/* You can add global styles to this file, and also import other style files */
@import 'material-icons/iconfont/outlined.css';

body {
  height: 100vh;
}

.mat-mdc-snack-bar-container {
  max-width: min-content;

  .mat-mdc-button.mat-mdc-snack-bar-action:not(:disabled) {
    --mat-text-button-state-layer-color: none !important;
  }

  .mdc-snackbar__surface {
    background-color: transparent !important;
    height: 0;
    margin-top: 8rem;
  }
}

.fc .fc-col-header-cell-cushion {
  color: #343a40;
}

.fc .fc-daygrid-day-number {
  color: #343a40;
}
.cdk-overlay-container {
  z-index: 1200 !important;
}
